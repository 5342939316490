<template>
  <v-container>
    <!-- 問題表示 -->
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="6">
        問題ID：<span v-html="cont.qOriginal.id"></span>
        <div class="report-problem">
          <a @click="errorReport_question()">
            <v-icon>mdi-chat-alert-outline</v-icon>
            <span v-show="$vuetify.breakpoint.smAndUp">問題の不具合を報告</span>
          </a>
        </div>
        <div
          style="white-space: pre-wrap; word-wrap: break-word; margin-top: 12px"
        >
          <div v-html="cont.question" />
          <Carousel :imagePaths="imagePaths" />
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <span v-show="contBtn.showCheckBox">
          <CheckBox ref="CheckBox" />
        </span>
      </v-col>
    </v-row>
    <br />
    <br />
    <v-divider></v-divider>

    <!-- 正解と補足 -->
    <AnswerAndComment ref="AnswerAndComment" />

    <!-- ボタン操作 -->
    <ContBtn
      ref="ContBtn"
      @fromContBtn_showComment="showComment"
      @fromContBtn_saveAndNext="saveAndNext"
    />
  </v-container>
</template>

<style>
.slide-base {
  max-width: 900px;
}
.slide {
  height: 0px;
  position: relative;
  width: 100%;
  overflow: hidden;
  /*padding-bottom: 50%;*/
  padding-top: 56.25%;
}
.slide iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
</style>

<script>
import { mapState } from 'vuex';
import CheckBox from '../compQuiz/CheckBox.vue';
import AnswerAndComment from '../compQuiz/AnswerAndComment.vue';
import ContBtn from '../compQuiz/ContBtn.vue';
import Carousel from '../compGeneral/Carousel.vue';

export default {
  //本編と同じコンポーネントを使用しています！
  components: {
    CheckBox,
    AnswerAndComment,
    ContBtn,
    Carousel,
  },
  data() {
    return {
      selKamokuName: '',
      isSearch: false,
      userData: {},
      questions: {},
      imagePaths: [],
    };
  },
  computed: { ...mapState(['cont', 'contBtn']) },
  async mounted() {
    await this.gf_keepActive(); //状態維持
    this.get_sessionData();
    this.set_question();
    this.imagePaths = await this.getQuestionImagePaths(this.selKamokuName);
  },
  methods: {
    //======================================================================================
    get_sessionData() {
      const data = JSON.parse(sessionStorage.getItem('data'));
      this.isSearch = data.isSearch;
      this.userData = data.userData;
      this.question = data.question;
      const selKamokuName = data.selKamokuName;
      //AnswerAndCommentにてsaveDataを参照している
      this.$store.commit('update_store', {
        path: 'saveData',
        key: 'selKamokuName',
        data: selKamokuName,
      });
      this.selKamokuName = selKamokuName;

      //SSの削除
      sessionStorage.clear();
    },
    set_question() {
      //チェックボックスなどの初期化
      this.contQ_initCont();

      //useData.contをstateに保存し、レンダリングさせる
      this.contQ_create(this.question);

      //検索から（通常通りの問題作成）
      if (this.isSearch) {
        //ここでボタンは初期化される。問題を解けば解説も表示される。
      } else {
        // 結果画面から
        //useData.contをstateに保存し、レンダリングさせる
        this.$store.commit('update_store', {
          path: 'state',
          key: 'contBtn',
          data: this.userData.contBtn,
        });

        //通常検索時にはContBtnから伝令があるので、切り分けている
        this.showComment();
      }
    },
    showComment() {
      //解答表示が終わり、judgeが済んだタイミング。ContBtnから伝令あり。
      const data = {
        selKamokuName: this.selKamokuName,
        question: this.question,
      };

      //コメントの表示
      this.$refs.AnswerAndComment.setContents(data);

      //全件解除ボタンを停止
      this.$store.commit('update_store', {
        path: 'contBtn',
        key: 'btnAllUncheck',
        data: true,
      });

      //v-ifにするとtrueになるまでrefを実行できないので、v-showにしているよ
      this.contBtn.showAnswerAndComment = true;
    },
    saveAndNext() {
      return;
      /*ここの仕組み
      １）compQuiz/ContBtn.vueにて
      ・ユーザーが正解or不正解のボタンを押すと下記が作動
        saveAndNext(isCorrect) {
          this.$emit('fromContBtn_saveAndNext', isCorrect);
        },
      ・✕ココから直接mixinsのcontQ_saveAndNext(isTrue)を起動させない

      ２）親コンポのsaveAndNext()（この関数）でわざわざ受け取る
      
      ３）親コンポのsaveAndNext()（この関数）からmixinsのcontQ_saveAndNext(isTrue)を起動する

      compQuiz/ContBtnの用途は2つあります。
      ア）通常の問題演習
      イ）問題を単体で表示させる

      イ）の場合１）から３）を直接起動すると、次の問題データが無いので、エラーになる

      データのやりとりを抜本的に見直す必要はあるかも。
      */
    },
    async errorReport_question() {
      //let msg = `問題ID：${this.cont.questionId}`;
      let msg = `作問ミスや不具合の修正提案をします。できるだけ詳しくご入力ください`;
      msg += `\n（改行不可、OKまたはEnterで送信）`;

      const res = window.prompt(msg);
      if (res === null || res === '') {
        return;
      }

      const ms_date = new Date().getTime();
      const kamokuName = this.$store.state.saveData.selKamokuName;

      const obj = {
        [ms_date]: {
          createdAt: ms_date,
          kamokuName: kamokuName,
          questionId: this.cont.questionId,
          user_email: '問題検索画面から報告',
          report: res,
        },
      };

      await this.utils_merge_firestore('errorReport', 'questions', obj);
      alert('情報提供ありがとうございました！');
    },
  },
};
</script>
